import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby-theme-material-ui";
import { kebabCase } from "lodash";
import { Box, Link as ExternalLink, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
  Layout, Meta, Section,
  Window,
} from "components";

const useStyles = makeStyles((theme) => ({
  gameImage: {
    height: `${theme.spacing(35)}px !important`,
    margin: "0 auto",
  },
  heroNav: {
    flex: `1 1 ${theme.spacing(35)}px`,
    minWidth: 0,
    margin: `${theme.spacing(5)}px !important`,
  },
  heroHeader: {
    flex: `1 1 ${theme.spacing(65)}px`,

    "& > *:not(:last-child)": {
      paddingBottom: theme.spacing(5),
    },
  },
  heroSection: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    paddingTop: 0,
  },
  gamesContainer: {
    backgroundColor: theme.palette.common.black,
  },
  section: {
    paddingTop: theme.spacing(15),
  },
  tool: {
    display: "flex",
    flexWrap: "wrap",

    "&:not(:last-child)": {
      marginBottom: theme.spacing(5),
    },
    "& *": {
      minWidth: 0,
    },
    "& > *": {
      flex: `1 1 ${theme.spacing(35)}px`,
    },
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },
  toolHeader: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
}));

export const toolsQuery = graphql`
  query tools {
    allAirtable(
      filter: {
        table: { eq: "Games" }
      },
      sort: {
        order: ASC
        fields: data___Name
      }
    ) {
      nodes {
        data {
          name: Name
          image: Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 1920,
                  placeholder: BLURRED
                )
              }
            }
          }
          imageAlt: Image_Alt
          tools: Tools {
            data {
              name: Name
              link: Link
              description: Description
            }
          }
        }
      }
    }
  }`;

/**
 * Render tools page.
 * @returns {JSX.Element} component
 */
export default function Page() {
  const data = useStaticQuery(toolsQuery);
  const classes = useStyles();
  const theme = useTheme();
  const games = data.allAirtable.nodes.map(({ data: gameData }) => {
    const {
      name: gameName,
      image,
      imageAlt,
      tools,
    } = gameData;
    const fluidImage = image && image.localFiles[0].childImageSharp.gatsbyImageData;
    return (
      <Game
        key={gameName}
        name={gameName}
        image={fluidImage}
        imageAlt={imageAlt}
        toolsData={tools}
      />
    );
  });
  const gameLinks = data.allAirtable.nodes.map(({ data: gameData }) => {
    const { name: gameName } = gameData;
    return (
      <Link
        key={gameName}
        to={`/tools#${kebabCase(gameName)}`}
        color="textSecondary"
        underline="none"
        noWrap
        style={{ minWidth: 0 }}
      >
        {gameName}
      </Link>
    );
  });

  return (
    <Layout>
      <Meta
        title="Tools"
        description="Check out our collection of useful tools for the games we play."
      />
      <Section className={classes.heroSection}>
        <Window maxHeight={theme.spacing(100)} className={classes.heroNav}>
          {gameLinks}
        </Window>
        <Box classes={{ root: classes.heroHeader }}>
          <Typography variant="h2" align="center">
            Game Tools
          </Typography>
          <Typography variant="h3" align="center">
            Here are some useful things to take the frustration out of gaming.
          </Typography>
        </Box>
      </Section>
      <Box classes={{ root: classes.gamesContainer }}>
        {games}
      </Box>
    </Layout>
  );
}

/**
 * Render game.
 * @param {Object} props props
 * @param {string} props.name game name
 * @param {Object} props.image fluid image
 * @param {string} props.imageAlt image alt description
 * @param {Object} props.toolsData game tools data
 * @returns {JSX.Element} component
 */
function Game({
  name,
  image, imageAlt,
  toolsData,
}) {
  const classes = useStyles();
  const tools = toolsData.map(({ data: toolData }) => {
    const { name: toolName, link, description } = toolData;
    return <Tool key={toolName} name={toolName} link={link} description={description} />;
  });
  return (
    <>
      <Section id={kebabCase(name)} className={classes.section}>
        <Typography variant="h3">
          {name}
        </Typography>
        <Box>
          {tools}
        </Box>
      </Section>
      {
        image && (
          <Box style={{ display: "flex" }}>
            <GatsbyImage image={image} alt={imageAlt} className={classes.gameImage} />
          </Box>
        )
      }
    </>
  );
}

/**
 * Render game tool.
 * @param {Object} props props
 * @param {string} props.name tool name
 * @param {string} props.link tool link
 * @param {string} props.description tool description
 */
function Tool({ name, link, description }) {
  const classes = useStyles();
  const theme = useTheme();
  const href = link.match(/^(https?:)?\/\//g) ? link : `//${link}`;
  return (
    <Box classes={{ root: classes.tool }}>
      <Box classes={{ root: classes.toolHeader }}>
        <Typography variant="h4" style={{ paddingBottom: theme.spacing(1) }}>
          {name}
        </Typography>
        <Box>
          <ExternalLink href={href} noWrap>
            {link}
          </ExternalLink>
        </Box>
      </Box>
      <Typography>
        {description}
      </Typography>
    </Box>
  );
}
